// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-responses-js": () => import("./../../src/pages/responses.js" /* webpackChunkName: "component---src-pages-responses-js" */),
  "component---src-templates-piece-detail-js": () => import("./../../src/templates/pieceDetail.js" /* webpackChunkName: "component---src-templates-piece-detail-js" */)
}

