module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"José Miguel","short_name":"José-Miguel","start_url":"/","background_color":"#ffffff","theme_color":"#51b7ff","display":"standalone","icon":"data/siteText/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b4f565ddf6d80336a4fb706ae617ea1f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/bvk/Documents/Active/vcl-jose/jose-site/src/layouts/coreLayout.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
